import {IControllers, Loader} from "../loader";
import {IController} from "../controller";
import {MenusController} from "../controllers/menus";

export class MenusLoader extends Loader {
    /* STATIC */
    public static override async build(): Promise<IControllers> {
        const onload: IController[] = [];

        const [
            {MenusController},
        ] = await Promise.all([
            import(/* webpackChunkName: "encuesta" */ `../controllers/menus`),
        ]);

        onload.push(...[
            MenusController,
        ])
        return {
            ...await super.build(),
            onload,
        };
    }

    /* INSTANCE */
}
